import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex, Text } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.SKILL_SCORE
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          SkillScore is the assessment platform which offers users the
          opportunity to test their skills in various industry such as
          programming, data, and design. Working in a small agile team of four,
          this project has been first delivered to users in a month and gained
          more than 6,000 users overnight after launched. Try it
          <a
            href="https://skillscore.skooldio.com/"
            style={{ marginLeft: "8px" }}
          >
            here!
          </a>
        </Content.HeaderParagraph>
        {/* <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Bangkok, Thailand",
            },
            { field: "program", value: "Sale Gallery" },
            { field: "client", value: "Advanced Living" },
            { field: "status", value: "completion 2017" },
            { field: "area", value: "400 sq.m." },
          ]}
        /> */}
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          useSrcSet={true}
          src={`${ROOT_IMAGE_PATH}/responsive/responsive@3x.png`}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>
      {/* <Content.Title mb={4} textAlign={"center"}>
        Features
      </Content.Title>

      <Content.Paragraph>
        This application consists of three main activities which are taking the
        quiz, seeing the report, and sharing the score.
      </Content.Paragraph> */}

      <Content.Section
        fullWidth={false}
        left={
          <>
            <Content.Title>Taking the quiz</Content.Title>
            <Content.Paragraph pr={5}>
              The main feature which allow users to come in and evaluate their
              skills throught the set of questions. Each question has its own
              type such as multiple-choices and fill-in-the-blank.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            useSrcSet={true}
            src={`${ROOT_IMAGE_PATH}/session/session@3x.png`}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />
      <Content.Section
        fullWidth={false}
        left={
          <>
            <Content.Title>See your score</Content.Title>
            <Content.Paragraph pr={5}>
              See the score and level after the test and keep learning to a get
              better score.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            useSrcSet={true}
            src={`${ROOT_IMAGE_PATH}/report/report@3x.png`}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />
      <Content.Section
        fullWidth={false}
        left={
          <>
            <Content.Title>Share!</Content.Title>
            <Content.Paragraph pr={5}>
              To increase the engagement and motivate users to improve
              themselves and get a better score, we build the share feature to
              let users share their score on social media including Facebook,
              Twitter, and LinkedIn.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/share.png`}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />

      <Content.Section>
        <Content.Title>Design System</Content.Title>
        <Content.Paragraph pr={5}>
          Because of limited time before the first launch, which is around one
          month, both developers and designers need to parallelly work on their
          tasks. Therefore, a design-system was created as the bridge between
          two teams to keep the consistency and make the codebase easier to
          maintain.
        </Content.Paragraph>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/design-system/design-system@3x.png`}
          width={"100%"}
          caption={"design system"}
        />
      </Content.Section>
      {/*
      <Content.Image
        src={`/assets/work/${workId}/cover.png`}
        width={"100%"}
        caption={"quiz page - cover"}
      /> */}

      <Content.Section>
        <Content.Title>Technical Development</Content.Title>
        <Content.Paragraph>
          The challenge of this project is to deliver the product within a short
          time, so we decided to use ready-to-use library and frameworks to
          avoid setting up large environment which could result in
          over-engineering.
        </Content.Paragraph>
        <Content.Paragraph>
          <Content.List>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Firebase Firestore{" "}
              </Text>
              - Since the data model is still simple in the early stage of the
              platform, we used google firebase firestore as our main database
              and use google cloud storage to store the assets.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Cloud functions
              </Text>{" "}
              - Although we can directly access the firestore from the client
              side, we decided to put all the backend logic in the cloud
              functions and use them as our REST APIs to completely separate the
              backend and frontend logic. This way we can easier maintain the
              codebase.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                ReactJS
              </Text>{" "}
              - We created the design system and implemented it with React,
              styled-components, and styled-system library to keep the UI
              consistency.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                ImageMagick
              </Text>{" "}
              - To let users share their scores on social media. ImageMagick
              library is used to dynamically create the customized sharing image
              of each person's score.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Analytics
              </Text>{" "}
              - Google Analytics, Hotjar, and Facebook Pixel are used in the
              website to gather data for future improvement.
            </Content.ListItem>
          </Content.List>
        </Content.Paragraph>
      </Content.Section>

      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Developer",
              value: ["Tadpol Rachatasumrit", "Pakapon Kaewmark"],
            },
            {
              field: "Designer",
              value: ["Ekkrit Foonngern"],
            },
            {
              field: "Product Owner",
              value: ["Chaiyawut Lertvichaivoravit"],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
